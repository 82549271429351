<template>
  <div>
    <div v-if="label" class="label">{{ label }}</div>
    <div class="base-input__wrapper">
      <div v-if="type === 'tel'" class="tel">+ 47</div>
      <input
        :class="classes"
        :type="type === 'tel' ? 'number' : type"
        :disabled="disabled"
        :placeholder="placeholder"
        :value="value"
        :min="type === 'tel' || type === 'number' ? 1 : null"
        @input="updateValue"
        @blur="$emit('blur')"
        @keypress="
          type === 'tel' || type === 'number' ? adjustNumberValue($event) : null
        "
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseInput",
  props: {
    dataFromApi: {
      type: Boolean,
      default: false
    },
    type: String,
    label: String,
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: String,
    value: String
  },
  computed: {
    classes() {
      const classes = ["base-input"];
      if (this.type === "tel") {
        classes.push("base-input--tel");
      }
      if (this.dataFromApi) {
        classes.push("base-input--from-api");
      }
      return classes;
    }
  },
  methods: {
    updateValue(e) {
      this.$emit("input", e.target.value);
    },
    adjustNumberValue(e) {
      let keyCode = e.keyCode ? e.keyCode : e.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        e.preventDefault();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/variables";

.base-input__wrapper {
  position: relative;
}

.base-input {
  border: 1px solid $color-input-border;
  border-radius: 4px;
  background-color: $color-input-background;
  font-size: 16px;
  line-height: 20px;
  padding: 14px 12px;
  width: 100%;
  box-sizing: border-box;
  transition: all 0.2s;

  &::placeholder {
    color: $color-input-placeholder;
  }

  &:focus {
    outline: none;
    border-color: $color-primary;
    box-shadow: inset 0 0 0 1px $color-primary;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.base-input--from-api {
  background-color: $color-primary-light;
}

.base-input--tel {
  width: 185px;
  padding-left: 55px;
}

.tel {
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
