<template>
  <div class="base-select__wrapper">
    <div v-if="label" class="label">{{ label }}</div>
    <div @click="toggleOptions">
      <input
        class="base-select"
        type="text"
        :placeholder="placeholder"
        :value="value"
        :disabled="disabled"
      />
      <div
        v-if="!disabled"
        class="base-select__triangle"
        :class="{ 'base-select__triangle--opened': isOpened }"
      ></div>
    </div>
    <div class="base-select base-select__options" v-if="isOpened && !disabled">
      <div
        v-for="(option, index) in options"
        :key="index"
        class="base-select__option"
        :class="{ 'base-select__option--selected': option === value }"
        @click="updateValue(option)"
      >
        {{ option }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseSelect",
  props: {
    label: String,
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: String,
    options: Array,
    value: String
  },
  data() {
    return {
      isOpened: false
    };
  },
  methods: {
    toggleOptions() {
      this.isOpened = !this.isOpened;
    },
    updateValue(value) {
      this.$emit("input", value);
      this.toggleOptions();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/variables";

.base-select__wrapper {
  position: relative;
}

.base-select {
  border: 1px solid $color-input-border;
  border-radius: 4px;
  background-color: $color-input-background;
  font-size: 16px;
  line-height: 20px;
  padding: 14px 12px;
  width: 100%;
  box-sizing: border-box;
  transition: all 0.2s;
  cursor: pointer;

  &::placeholder {
    color: $color-input-placeholder;
  }

  &:focus {
    outline: none;
    border-color: $color-primary;
    box-shadow: inset 0 0 0 1px $color-primary;
  }

  &:disabled {
    pointer-events: none;
  }
}

.base-select__triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 5px 6px 5px;
  border-color: transparent transparent $color-font transparent;
  position: absolute;
  top: 21px;
  right: 14px;
  transition: all 0.2s;
  transform: rotate(180deg);
}

.base-select__triangle--opened {
  transform: none;
}

.base-select__options {
  position: absolute;
  top: 51px;
  padding: 8px 0;
  box-shadow: 0px 5px 10px $color-gray-dark;
  max-height: 230px;
  overflow-y: auto;
  z-index: 1;
}

.base-select__option {
  padding: 14px 12px;

  &:not(:last-child) {
    border-bottom: 1px solid $color-input-border;
  }

  &:hover {
    cursor: pointer;
    background-color: $color-gray;
  }
}

.base-select__option--selected {
  background-color: $color-primary;
  color: white;
  border-bottom-color: $color-primary;

  &:hover {
    background-color: lighten($color-primary, 10%);
    border-bottom-color: lighten($color-primary, 10%);
  }
}
</style>
