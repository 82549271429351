<template>
  <div class="step-1">
    <div class="step-group">
      <p class="intro-text">
        Begynn med å legge inn ditt telefonnummer,
        så order vi så mye som mulig automatisk.
      </p>
    </div>

    <!-- Phone -->
    <div
      class="step-group"
      :class="{
        'step-group--error':
          stepValidationValue.showErrors && !phoneData.isValid
      }"
    >
      <BaseInput
        type="tel"
        label="Telefonnummer"
        placeholder="00 00 00 00"
        v-model="phoneValue"
        @blur="getPersonData"
        @input="stepValidationValue.showErrors ? validPhone() : null"
      />
      <div v-if="stepValidationValue.showErrors" class="error-message">
        <div v-for="(error, index) in phoneData.errors" :key="index">
          {{ error }}
        </div>
      </div>
    </div>

    <!-- First name -->
    <div
      class="step-group"
      :class="{
        'step-group--error':
          stepValidationValue.showErrors && !firstNameData.isValid
      }"
    >
      <BaseInput
        type="text"
        label="Fornavn"
        placeholder="F.eks. Kari"
        v-model="firstNameValue"
        :dataFromApi="
          firstNameValue ? personData.firstName === firstNameValue : false
        "
        @input="stepValidationValue.showErrors ? validFirstName() : null"
      />
      <div v-if="stepValidationValue.showErrors" class="error-message">
        <div v-for="(error, index) in firstNameData.errors" :key="index">
          {{ error }}
        </div>
      </div>
    </div>

    <!-- Last name -->
    <div
      class="step-group"
      :class="{
        'step-group--error':
          stepValidationValue.showErrors && !lastNameData.isValid
      }"
    >
      <BaseInput
        type="text"
        label="Etternavn"
        placeholder="F.eks. Nordmann"
        v-model="lastNameValue"
        :dataFromApi="
          lastNameValue ? personData.lastName === lastNameValue : false
        "
        @input="stepValidationValue.showErrors ? validLastName() : null"
      />
      <div v-if="stepValidationValue.showErrors" class="error-message">
        <div v-for="(error, index) in lastNameData.errors" :key="index">
          {{ error }}
        </div>
      </div>
    </div>

    <!-- Customer type -->
    <div class="step-group">
      <BaseRadioGroup
        type="horizontal"
        label="Kundetype"
        :items="typeOfCustomerItems"
        v-model="customerTypeValue"
      />
    </div>

    <!-- Email -->
    <div
      class="step-group"
      :class="{
        'step-group--error':
          stepValidationValue.showErrors && !emailData.isValid
      }"
    >
      <BaseInput
        type="email"
        label="E-postadresse"
        placeholder="F.eks. kari.nordmann@example.com"
        v-model="emailValue"
        @input="stepValidationValue.showErrors ? validEmail() : null"
      />
      <div v-if="stepValidationValue.showErrors" class="error-message">
        <div v-for="(error, index) in emailData.errors" :key="index">
          {{ error }}
        </div>
      </div>
    </div>

    <div
      v-if="customerTypeValue === 'corporate'"
      class="step-group"
      :class="{
        'step-group--error':
          stepValidationValue.showErrors &&
          (!orgNumberData.isValid || !companyNameData.isValid)
      }"
    >
      <!-- Comapny name -->
      <BaseInput
        type="text"
        label="Organisasjonsnavn og -nummer"
        placeholder="Eksempel 1 AS"
        v-model="companyNameValue"
        :dataFromApi="
          companyNameValue
            ? organisationData.companyName === companyNameValue
            : false
        "
        @blur="getOrganisationNumber"
        @input="stepValidationValue.showErrors ? validComapnyName() : null"
      />
      <div v-if="stepValidationValue.showErrors" class="error-message">
        <div v-for="(error, index) in companyNameData.errors" :key="index">
          {{ error }}
        </div>
      </div>

      <!-- Org number -->
      <BaseInput
        type="number"
        placeholder="000 000 000"
        v-model="orgNumberValue"
        :dataFromApi="
          orgNumberValue ? organisationData.orgNumber === orgNumberValue : false
        "
        @blur="getOrganisationName"
        @input="stepValidationValue.showErrors ? validOrgNumber() : null"
      />
      <div v-if="stepValidationValue.showErrors" class="error-message">
        <div v-for="(error, index) in orgNumberData.errors" :key="index">
          {{ error }}
        </div>
      </div>
    </div>

    <div
      class="step-group"
      :class="{
        'step-group--error':
          stepValidationValue.showErrors &&
          (!addressData.isValid || !postalCodeData.isValid || !townData.isValid)
      }"
    >
      <!-- Address -->
      <BaseInput
        type="text"
        label="Adresse"
        placeholder="Storgata 1"
        v-model="addressValue"
        :dataFromApi="isAddressFromApi"
        @blur="cleanAddress"
        @input="stepValidationValue.showErrors ? validAddress() : null"
      />
      <div v-if="stepValidationValue.showErrors" class="error-message">
        <div v-for="(error, index) in addressData.errors" :key="index">
          {{ error }}
        </div>
      </div>

      <!-- Postal code -->
      <BaseInput
        type="number"
        placeholder="Postnummer"
        v-model="postalCodeValue"
        :dataFromApi="isPostalCodeFromApi"
        @blur="getTown"
        @input="stepValidationValue.showErrors ? validPostalCode() : null"
      />
      <div v-if="stepValidationValue.showErrors" class="error-message">
        <div v-for="(error, index) in postalCodeData.errors" :key="index">
          {{ error }}
        </div>
      </div>

      <!-- Town -->
      <BaseInput
        type="text"
        placeholder="Sted"
        v-model="townValue"
        :dataFromApi="isTownFromApi"
        @input="stepValidationValue.showErrors ? validTown() : null"
      />
      <div v-if="stepValidationValue.showErrors" class="error-message">
        <div v-for="(error, index) in townData.errors" :key="index">
          {{ error }}
        </div>
      </div>
      <BaseSelect value="Norge" disabled />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapMutations } from "vuex";

import { cleanAddress } from "../main";

import BaseInput from "../components/BaseInput";
import BaseRadioGroup from "../components/BaseRadioGroup";
import BaseSelect from "../components/BaseSelect";

export default {
  components: {
    BaseInput,
    BaseRadioGroup,
    BaseSelect
  },
  data() {
    return {
      phone: "",
      firstName: "",
      lastName: "",
      email: "",
      orgNumber: "",
      comapnyName: "",
      address: "",
      postalCode: "",
      town: "",
      typeOfCustomerItems: [
        {
          name: "Privatperson",
          value: "private"
        },
        {
          name: "Firma/Organisasjon",
          value: "corporate"
        }
      ],
      personData: {},
      organisationData: {}
    };
  },
  computed: {
    stepValidationValue() {
      return this.$store.state.step1.stepValidation;
    },

    phoneData() {
      return this.$store.state.step1.phone;
    },
    phoneValue: {
      get() {
        return this.$store.state.step1.phone.value;
      },
      set(value) {
        this.$store.commit("step1/updatePhone", value);
      }
    },

    firstNameData() {
      return this.$store.state.step1.firstName;
    },
    firstNameValue: {
      get() {
        return this.$store.state.step1.firstName.value;
      },
      set(value) {
        this.$store.commit("step1/updateFirstName", value);
      }
    },

    middleNameValue: {
      get() {
        return this.$store.state.step1.middleName.value;
      },
      set(value) {
        this.$store.commit("step1/updateMiddleName", value);
      }
    },

    lastNameData() {
      return this.$store.state.step1.lastName;
    },
    lastNameValue: {
      get() {
        return this.$store.state.step1.lastName.value;
      },
      set(value) {
        this.$store.commit("step1/updateLastName", value);
      }
    },

    customerTypeValue: {
      get() {
        return this.$store.state.step1.customerType.value;
      },
      set(value) {
        this.$store.commit("step1/updateCustomerType", value);
        if (value === "corporate") {
          this.$store.commit(
            "step1/updateAddress",
            this.organisationData.address || ""
          );
          this.$store.commit(
            "step1/updatePostalCode",
            this.organisationData.postalCode || ""
          );
          this.$store.commit(
            "step1/updateTown",
            this.organisationData.town || ""
          );
        } else if (value === "private") {
          this.$store.commit(
            "step1/updateAddress",
            this.personData.address || ""
          );
          this.$store.commit(
            "step1/updatePostalCode",
            this.personData.postalCode || ""
          );
          this.$store.commit("step1/updateTown", this.personData.city || "");
        }
      }
    },

    emailData() {
      return this.$store.state.step1.email;
    },
    emailValue: {
      get() {
        return this.$store.state.step1.email.value;
      },
      set(value) {
        this.$store.commit("step1/updateEmail", value);
      }
    },

    orgNumberData() {
      return this.$store.state.step1.orgNumber;
    },
    orgNumberValue: {
      get() {
        return this.$store.state.step1.orgNumber.value;
      },
      set(value) {
        this.$store.commit("step1/updateOrgNumber", value);
      }
    },

    companyNameData() {
      return this.$store.state.step1.comapnyName;
    },
    companyNameValue: {
      get() {
        return this.$store.state.step1.comapnyName.value;
      },
      set(value) {
        this.$store.commit("step1/updateComapnyName", value);
      }
    },

    addressData() {
      return this.$store.state.step1.address;
    },
    addressValue: {
      get() {
        return this.$store.state.step1.address.value;
      },
      set(value) {
        this.$store.commit("step1/updateAddress", value);
      }
    },

    postalCodeData() {
      return this.$store.state.step1.postalCode;
    },
    postalCodeValue: {
      get() {
        return this.$store.state.step1.postalCode.value;
      },
      set(value) {
        this.$store.commit("step1/updatePostalCode", value);
      }
    },

    townData() {
      return this.$store.state.step1.town;
    },
    townValue: {
      get() {
        return this.$store.state.step1.town.value;
      },
      set(value) {
        this.$store.commit("step1/updateTown", value);
      }
    },

    isAddressFromApi() {
      if (this.customerTypeValue === "corporate") {
        return this.organisationData && this.addressValue
          ? this.organisationData.address === this.addressValue
          : false;
      } else if (this.customerTypeValue === "private") {
        return this.personData && this.addressValue
          ? this.personData.address === this.addressValue
          : false;
      }

      return false;
    },

    isPostalCodeFromApi() {
      if (this.customerTypeValue === "corporate") {
        return this.organisationData && this.postalCodeValue
          ? this.organisationData.postalCode === this.postalCodeValue
          : false;
      } else if (this.customerTypeValue === "private") {
        return this.personData && this.postalCodeValue
          ? this.personData.postalCode === this.postalCodeValue
          : false;
      }

      return false;
    },

    isTownFromApi() {
      if (this.customerTypeValue === "corporate") {
        return this.organisationData && this.townValue
          ? this.organisationData.town === this.townValue
          : false;
      } else if (this.customerTypeValue === "private") {
        return this.personData && this.townValue
          ? this.personData.city === this.townValue
          : false;
      }

      return false;
    }
  },
  methods: {
    ...mapMutations("step1", [
      "validPhone",
      "validFirstName",
      "validLastName",
      "validEmail",
      "validOrgNumber",
      "validComapnyName",
      "validAddress",
      "validPostalCode",
      "validTown"
    ]),
    getPersonData() {
      const phoneNumber = this.phoneValue;
      axios
        .get(
          `${process.env.VUE_APP_CONTACT_FORM_API_URL}phone_number/${phoneNumber}/`
        )
        .then(response => {
          this.personData = response.data;
          this.$store.commit("step1/updateAddress", this.personData.address);
          this.$store.commit("step1/updateTown", this.personData.city);
          this.$store.commit(
            "step1/updateCompanyName",
            this.personData.companyName
          );
          this.$store.commit(
            "step1/updateCustomerType",
            this.personData.customerType === "P" ? "private" : "corporate"
          );
          this.$store.commit(
            "step1/updateFirstName",
            this.personData.firstName
          );
          this.$store.commit("step1/updateLastName", this.personData.lastName);
          this.$store.commit(
            "step1/updateMiddleName",
            this.personData.middleName
          );
          this.$store.commit(
            "step1/updateOrgNumber",
            this.personData.organizationNumber
          );
          this.$store.commit(
            "step1/updatePostalCode",
            this.personData.postalCode
          );
          if (this.personData.organizationNumber) {
            this.getOrganisationName();
          } else if (this.personData.companyName) {
            this.getOrganisationNumber();
          }
        }).catch(error => {
          this.$notify({
            title: "Obs",
            text: "Kunne ikke finne data til dette nummeret. Vennligst fyll ut de neste feltene manuelt.",
            group: "warning"
          });
          console.log(error);
        });
    },
    getOrganisationName() {
      axios
        .get(
          `https://data.brreg.no/enhetsregisteret/api/enheter/${this.orgNumberValue}/`
        )
        .then(response => {
          const data = response.data;
          this.$store.commit("step1/updateComapnyName", data.navn);
          this.organisationData.companyName = data.navn;

          if (data.forretningsadresse?.adresse) {
            this.$store.commit(
              "step1/updateAddress",
              data.forretningsadresse.adresse.join(" ")
            );
            this.organisationData.address = data.forretningsadresse.adresse.join(" ");
          } else {
            this.$store.commit("step1/updateAddress", "");
            this.organisationData.address = "";
          }

          if (data.forretningsadresse?.postnummer) {
            this.$store.commit(
              "step1/updatePostalCode",
              data.forretningsadresse.postnummer
            );
            this.organisationData.postalCode = data.forretningsadresse.postnummer;
          } else {
            this.$store.commit("step1/updatePostalCode", "");
            this.organisationData.postalCode = "";
          }

          if (data.forretningsadresse?.poststed) {
            this.$store.commit("step1/updateTown", data.forretningsadresse.poststed);
            this.organisationData.town = data.forretningsadresse.poststed;
          } else {
            this.$store.commit("step1/updateTown", "");
            this.organisationData.town = "";
          }
        });
    },
    getOrganisationNumber() {
      axios
        .get(`https://data.brreg.no/enhetsregisteret/api/enheter`, {
          params: {
            navn: this.companyNameValue
          }
        })
        .then(response => {
          const data = response.data._embedded.enheter[0];
          this.$store.commit("step1/updateOrgNumber", data.organisasjonsnummer);
          this.organisationData.orgNumber = data.organisasjonsnummer;

          if (data.forretningsadresse?.adresse) {
            this.$store.commit(
              "step1/updateAddress",
              data.forretningsadresse.adresse.join(" ")
            );
            this.organisationData.address = data.forretningsadresse.adresse.join(" ");
          } else {
            this.$store.commit("step1/updateAddress", "");
            this.organisationData.address = "";
          }

          if (data.forretningsadresse?.postnummer) {
            this.$store.commit(
              "step1/updatePostalCode",
              data.forretningsadresse.postnummer
            );
            this.organisationData.postalCode = data.forretningsadresse.postnummer;
          } else {
            this.$store.commit("step1/updatePostalCode", "");
            this.organisationData.postalCode = "";
          }

          if (data.forretningsadresse?.poststed) {
            this.$store.commit(
              "step1/updateTown",
              data.forretningsadresse.poststed
            );
            this.organisationData.town = data.forretningsadresse.poststed;
          } else {
            this.$store.commit("step1/updateTown", "");
            this.organisationData.town = "";
          }
        });
    },
    getTown() {
      axios
        .get("https://ws.geonorge.no/adresser/v1/sok", {
          params: {
            postnummer: this.postalCodeValue
          }
        })
        .then(response => {
          const data = response.data.adresser[0];
          this.$store.commit("step1/updateTown", data.poststed);
          this.organisationData.town = data.poststed;
        });
    },
    cleanAddress() {
      const data = cleanAddress(this.addressData.value);
      this.$store.commit("step1/updateAddress", data);
      this.organisationData.address = data;
    }
  }
};
</script>

<style lang="scss" scoped>
.step-1 {
  width: 100%;
}

.intro-text {
  margin-top: 0;
}
</style>
