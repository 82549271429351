<template>
  <div>
    <div v-if="label" class="label">{{ label }}</div>
    <div :class="classes">
      <label class="base-radio" v-for="(item, index) in items" :key="index">
        {{ item.name }}
        <input
          type="radio"
          :value="item.value"
          :checked="item.value === value"
          @change="updateValue(item.value)"
        />
        <span class="base-radio__checkmark"></span>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseRadioGroup",
  props: {
    type: String,
    label: String,
    items: {
      type: Array,
      required: true
    },
    value: String
  },
  computed: {
    classes() {
      const classes = ["base-radio-group"];
      if (this.type === "horizontal") {
        classes.push("base-radio-group--horizontal");
      }
      return classes;
    }
  },
  methods: {
    updateValue(value) {
      this.$emit("input", value);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/variables";

.base-radio-group {
  display: flex;
  flex-direction: column;
}

.base-radio-group--horizontal {
  flex-direction: row;
}

.base-radio {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-right: 20px;
  cursor: pointer;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  &:hover input ~ .base-radio__checkmark {
    background-color: $color-input-background;
  }

  input:checked ~ .base-radio__checkmark:after {
    display: block;
  }
}

.base-radio__checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: white;
  border-radius: 50%;
  border: 1px solid $color-gray;
  transition: all 0.2s;

  &::after {
    content: "";
    position: absolute;
    display: none;
    transition: all 0.2s;
  }
}

.base-radio .base-radio__checkmark:after {
  top: 3px;
  left: 3px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: $color-font;
}
</style>
