var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"step-1"},[_vm._m(0),_c('div',{staticClass:"step-group",class:{
      'step-group--error':
        _vm.stepValidationValue.showErrors && !_vm.phoneData.isValid
    }},[_c('BaseInput',{attrs:{"type":"tel","label":"Telefonnummer","placeholder":"00 00 00 00"},on:{"blur":_vm.getPersonData,"input":function($event){_vm.stepValidationValue.showErrors ? _vm.validPhone() : null}},model:{value:(_vm.phoneValue),callback:function ($$v) {_vm.phoneValue=$$v},expression:"phoneValue"}}),(_vm.stepValidationValue.showErrors)?_c('div',{staticClass:"error-message"},_vm._l((_vm.phoneData.errors),function(error,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(error)+" ")])}),0):_vm._e()],1),_c('div',{staticClass:"step-group",class:{
      'step-group--error':
        _vm.stepValidationValue.showErrors && !_vm.firstNameData.isValid
    }},[_c('BaseInput',{attrs:{"type":"text","label":"Fornavn","placeholder":"F.eks. Kari","dataFromApi":_vm.firstNameValue ? _vm.personData.firstName === _vm.firstNameValue : false},on:{"input":function($event){_vm.stepValidationValue.showErrors ? _vm.validFirstName() : null}},model:{value:(_vm.firstNameValue),callback:function ($$v) {_vm.firstNameValue=$$v},expression:"firstNameValue"}}),(_vm.stepValidationValue.showErrors)?_c('div',{staticClass:"error-message"},_vm._l((_vm.firstNameData.errors),function(error,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(error)+" ")])}),0):_vm._e()],1),_c('div',{staticClass:"step-group",class:{
      'step-group--error':
        _vm.stepValidationValue.showErrors && !_vm.lastNameData.isValid
    }},[_c('BaseInput',{attrs:{"type":"text","label":"Etternavn","placeholder":"F.eks. Nordmann","dataFromApi":_vm.lastNameValue ? _vm.personData.lastName === _vm.lastNameValue : false},on:{"input":function($event){_vm.stepValidationValue.showErrors ? _vm.validLastName() : null}},model:{value:(_vm.lastNameValue),callback:function ($$v) {_vm.lastNameValue=$$v},expression:"lastNameValue"}}),(_vm.stepValidationValue.showErrors)?_c('div',{staticClass:"error-message"},_vm._l((_vm.lastNameData.errors),function(error,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(error)+" ")])}),0):_vm._e()],1),_c('div',{staticClass:"step-group"},[_c('BaseRadioGroup',{attrs:{"type":"horizontal","label":"Kundetype","items":_vm.typeOfCustomerItems},model:{value:(_vm.customerTypeValue),callback:function ($$v) {_vm.customerTypeValue=$$v},expression:"customerTypeValue"}})],1),_c('div',{staticClass:"step-group",class:{
      'step-group--error':
        _vm.stepValidationValue.showErrors && !_vm.emailData.isValid
    }},[_c('BaseInput',{attrs:{"type":"email","label":"E-postadresse","placeholder":"F.eks. kari.nordmann@example.com"},on:{"input":function($event){_vm.stepValidationValue.showErrors ? _vm.validEmail() : null}},model:{value:(_vm.emailValue),callback:function ($$v) {_vm.emailValue=$$v},expression:"emailValue"}}),(_vm.stepValidationValue.showErrors)?_c('div',{staticClass:"error-message"},_vm._l((_vm.emailData.errors),function(error,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(error)+" ")])}),0):_vm._e()],1),(_vm.customerTypeValue === 'corporate')?_c('div',{staticClass:"step-group",class:{
      'step-group--error':
        _vm.stepValidationValue.showErrors &&
        (!_vm.orgNumberData.isValid || !_vm.companyNameData.isValid)
    }},[_c('BaseInput',{attrs:{"type":"text","label":"Organisasjonsnavn og -nummer","placeholder":"Eksempel 1 AS","dataFromApi":_vm.companyNameValue
          ? _vm.organisationData.companyName === _vm.companyNameValue
          : false},on:{"blur":_vm.getOrganisationNumber,"input":function($event){_vm.stepValidationValue.showErrors ? _vm.validComapnyName() : null}},model:{value:(_vm.companyNameValue),callback:function ($$v) {_vm.companyNameValue=$$v},expression:"companyNameValue"}}),(_vm.stepValidationValue.showErrors)?_c('div',{staticClass:"error-message"},_vm._l((_vm.companyNameData.errors),function(error,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(error)+" ")])}),0):_vm._e(),_c('BaseInput',{attrs:{"type":"number","placeholder":"000 000 000","dataFromApi":_vm.orgNumberValue ? _vm.organisationData.orgNumber === _vm.orgNumberValue : false},on:{"blur":_vm.getOrganisationName,"input":function($event){_vm.stepValidationValue.showErrors ? _vm.validOrgNumber() : null}},model:{value:(_vm.orgNumberValue),callback:function ($$v) {_vm.orgNumberValue=$$v},expression:"orgNumberValue"}}),(_vm.stepValidationValue.showErrors)?_c('div',{staticClass:"error-message"},_vm._l((_vm.orgNumberData.errors),function(error,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(error)+" ")])}),0):_vm._e()],1):_vm._e(),_c('div',{staticClass:"step-group",class:{
      'step-group--error':
        _vm.stepValidationValue.showErrors &&
        (!_vm.addressData.isValid || !_vm.postalCodeData.isValid || !_vm.townData.isValid)
    }},[_c('BaseInput',{attrs:{"type":"text","label":"Adresse","placeholder":"Storgata 1","dataFromApi":_vm.isAddressFromApi},on:{"blur":_vm.cleanAddress,"input":function($event){_vm.stepValidationValue.showErrors ? _vm.validAddress() : null}},model:{value:(_vm.addressValue),callback:function ($$v) {_vm.addressValue=$$v},expression:"addressValue"}}),(_vm.stepValidationValue.showErrors)?_c('div',{staticClass:"error-message"},_vm._l((_vm.addressData.errors),function(error,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(error)+" ")])}),0):_vm._e(),_c('BaseInput',{attrs:{"type":"number","placeholder":"Postnummer","dataFromApi":_vm.isPostalCodeFromApi},on:{"blur":_vm.getTown,"input":function($event){_vm.stepValidationValue.showErrors ? _vm.validPostalCode() : null}},model:{value:(_vm.postalCodeValue),callback:function ($$v) {_vm.postalCodeValue=$$v},expression:"postalCodeValue"}}),(_vm.stepValidationValue.showErrors)?_c('div',{staticClass:"error-message"},_vm._l((_vm.postalCodeData.errors),function(error,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(error)+" ")])}),0):_vm._e(),_c('BaseInput',{attrs:{"type":"text","placeholder":"Sted","dataFromApi":_vm.isTownFromApi},on:{"input":function($event){_vm.stepValidationValue.showErrors ? _vm.validTown() : null}},model:{value:(_vm.townValue),callback:function ($$v) {_vm.townValue=$$v},expression:"townValue"}}),(_vm.stepValidationValue.showErrors)?_c('div',{staticClass:"error-message"},_vm._l((_vm.townData.errors),function(error,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(error)+" ")])}),0):_vm._e(),_c('BaseSelect',{attrs:{"value":"Norge","disabled":""}})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"step-group"},[_c('p',{staticClass:"intro-text"},[_vm._v(" Begynn med å legge inn ditt telefonnummer, så order vi så mye som mulig automatisk. ")])])}]

export { render, staticRenderFns }